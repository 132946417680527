let BASE_URL = window.location.href.includes("bookcheck-client.onrender.com") || window.location.href.includes("qualitycheck.pilot.com")
  ? "https://bookcheck-api.onrender.com/"
  : "http://localhost:4000/";

const callAPI = async (endpoint, params) => {
  const url =
    BASE_URL +
    endpoint +
    "?redirect_uri=" +
    (window.location.href.includes("bookcheck-client.onrender.com")
      ? "https://bookcheck-client.onrender.com/"
      : window.location.href);
  const response = await fetch(url);
  const json = await response.json();

  return json;
};
// Function to handle the event stream
const handleEventStream = (
  authCode,
  companyId,
  setCompanyData,
  setChecklist,
  setFinalData,
  setError
) => {
  const url = `${BASE_URL}/insights?code=${encodeURIComponent(
    authCode
  )}&realmId=${encodeURIComponent(companyId)}`;
  const eventSource = new EventSource(url);

  eventSource.onopen = (event) => {
    //console.log("Connection to server opened.");
  };

  eventSource.onmessage = (event) => {
    //console.log("Message received:", event.data);

    const data = JSON.parse(event.data);

    if (data["event"] === "LOGGED_IN")
      setChecklist({
        logged_in: true,
        runway: false,
        balance_sheet_review: false,
        pandl_review: false,
        additional_checks: false,
        final: false,
      });

    if (data["event"] === "RUNWAY")
      setChecklist({
        logged_in: true,
        runway: true,
        balance_sheet_review: false,
        pandl_review: false,
        additional_checks: false,
        final: false,
      });

    if (data["event"] === "BALANCE_SHEET_REVIEW")
      setChecklist({
        logged_in: true,
        runway: true,
        balance_sheet_review: true,
        pandl_review: false,
        additional_checks: false,
        final: false,
      });

    if (data["event"] === "PANDL_REVIEW")
      setChecklist({
        logged_in: true,
        runway: true,
        balance_sheet_review: true,
        pandl_review: true,
        additional_checks: false,
        final: false,
      });

    if (data["event"] === "ADDITIONAL_CHECKS")
      setChecklist({
        logged_in: true,
        runway: true,
        balance_sheet_review: true,
        pandl_review: true,
        additional_checks: true,
        final: false,
      });

    if (data["event"] === "COMPANY_DATA") setCompanyData(data["data"]);
    
  if (data["event"] === "FINAL") {
      setChecklist({
        logged_in: true,
        runway: true,
        balance_sheet_review: true,
        pandl_review: true,
        additional_checks: true,
        final: true,
      });

      setFinalData(data["data"]);

      eventSource.close()
    }
  };

  eventSource.onerror = (event) => {
    console.error(event);

    setError({ type: "MAIN", description: "something" });
    eventSource.close();
  };
};

export const OAuthURL = async () => {
  const url = await callAPI("/oauth-url", {});
  return url["url"];
};

export const InsightsGen = (
  code,
  realmId,
  setCompanyData,
  setChecklist,
  setFinalData,
  setError
) => {
  handleEventStream(
    code,
    realmId,
    setCompanyData,
    setChecklist,
    setFinalData,
    setError
  );
};
